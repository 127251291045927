@charset "UTF-8";
.img_logo {
  width: 72px;
  vertical-align: middle;
  margin-left: 5px; }

.font_rounded {
  font-family: rounded_bold;
  color: #444; }

.kv {
  background: url(../images/top_kv.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 580px;
  position: relative;
  text-align: center;
  color: #fff;
  font-family: rounded_bold; }
  @media screen and (min-width: 640px) {
    .kv {
      background-size: contain; } }
  .kv .top_logo {
    font-size: 1.2rem;
    padding-top: 20px; }
  .kv .ttl_top {
    font-size: 3.5rem;
    margin-top: 30px;
    font-family: rounded_bold;
    letter-spacing: -1px; }
    @media screen and (max-width: 340px) {
      .kv .ttl_top {
        font-size: 9.3vw; } }
  .kv .ttl_under {
    font-size: 1.8rem;
    margin-top: 15px;
    letter-spacing: 0.05em;
    letter-spacing: -.5px; }
    @media screen and (max-width: 340px) {
      .kv .ttl_under {
        font-size: 4.8vw; } }
  .kv .emphasis {
    text-emphasis: filled circle #fdd000;
    -webkit-text-emphasis: filled circle #fdd000; }
  .kv .catch_color {
    color: #fdd000; }

.contact_wrap {
  padding: 40px 0;
  text-align: center; }
  .contact_wrap .btn_line {
    width: 90%;
    margin: 0 auto; }
  .contact_wrap .contact_tel {
    background: #fff;
    padding: 20px 5%;
    margin-top: 30px;
    font-size: 1.8rem;
    border-radius: 8px; }
  .contact_wrap .btn_tel {
    margin-top: 12px; }
  .contact_wrap .reception_time {
    font-size: 1.2rem;
    margin-top: 15px; }

.about_concierge {
  padding: 40px 5% 30px;
  background: url(../images/bg_about_concierge.jpg) no-repeat;
  background-size: cover;
  text-align: center; }
  .about_concierge .ttl {
    font-size: 1.8rem;
    color: #fff;
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15); }
  .about_concierge .balloon_area {
    margin-top: 30px; }
  .about_concierge .balloon {
    width: 90%;
    height: 100px;
    position: relative;
    text-align: left; }
  .about_concierge .balloon_txt {
    width: 90%;
    font-size: 1.5rem;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -64%); }
    @media screen and (max-width: 340px) {
      .about_concierge .balloon_txt {
        font-size: 4vw; } }
  .about_concierge .balloon.left {
    background: url(../images/balloon_left.png) no-repeat;
    background-size: 100%; }
  .about_concierge .balloon.right {
    background: url(../images/balloon_right.png) no-repeat;
    background-size: 100%;
    margin-left: auto;
    width: 86%; }

.kimura_area {
  margin-top: 40px;
  text-align: center; }
  .kimura_area .ttl {
    color: #f15e5e;
    font-size: 3rem;
    display: inline-block; }
  .kimura_area .slash_txt {
    position: relative; }
    .kimura_area .slash_txt::before, .kimura_area .slash_txt::after {
      content: "";
      width: 2px;
      height: 1em;
      position: absolute;
      display: inline-block;
      top: .2em;
      background-repeat: no-repeat;
      border-left: 2px solid #fcc; }
    .kimura_area .slash_txt::before {
      transform: rotate(-20deg);
      left: -.8em; }
    .kimura_area .slash_txt::after {
      transform: rotate(20deg);
      right: -.8em; }
  .kimura_area .desc {
    font-size: 1.8rem;
    margin-top: 10px;
    color: #000; }
  .kimura_area .img_consal {
    margin-top: 20px; }

.reason_area {
  text-align: center;
  padding: 40px 0; }
  .reason_area .ttl {
    font-size: 2.5rem;
    color: #fff; }
  .reason_area .reason_box {
    background: #fff;
    width: 84%;
    margin: 20px auto 0;
    padding: 20px;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.15); }
  .reason_area .detail_txt {
    font-size: 1.8rem;
    color: #000; }
    .reason_area .detail_txt .emphasis {
      color: #f15e5e; }
  .reason_area .img_reason {
    margin-top: 20px; }
  .reason_area .img_small {
    width: 40%; }
  .reason_area .img_large {
    width: 60%; }

.desc_concierge {
  width: 80%;
  margin: 30px auto 40px;
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.6; }
  .desc_concierge .txt {
    margin-top: 30px; }

.house_style {
  text-align: center; }
  .house_style .style_wrap {
    background: #f4f2ec;
    padding: 20px 5%; }
    .house_style .style_wrap:not(:first-of-type) {
      margin-top: 10px; }
  .house_style .style_name {
    position: relative;
    font-size: 18px;
    font-family: rounded_bold; }
  .house_style .icon {
    font-size: 1em;
    position: relative;
    left: 1em;
    display: inline-block; }
    .house_style .icon::before {
      content: "";
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: absolute;
      top: 0;
      left: -2em; }
  .house_style .icon.icon_bungalow::before {
    background: url(../images/icon_house.png) no-repeat;
    background-size: contain; }
  .house_style .icon.icon_sofa::before {
    background: url(../images/icon_sofa.png) no-repeat;
    background-size: contain; }
  .house_style .icon.icon_brick::before {
    background: url(../images/icon_brick.png) no-repeat;
    background-size: contain; }
  .house_style .icon.icon_modern::before {
    background: url(../images/icon_folding-fan.png) no-repeat;
    background-size: contain;
    top: .3em; }
  .house_style .icon.icon_cafe::before {
    background: url(../images/icon_cup.png) no-repeat;
    background-size: contain;
    top: .4em; }
  .house_style .icon.icon_simple::before {
    background: url(../images/icon_light.png) no-repeat;
    background-size: contain; }
  .house_style .img_style {
    width: 100%;
    margin-top: 15px; }
  .house_style .coverage {
    font-size: 12px;
    text-align: right;
    margin-top: 10px;
    color: #666; }

.check_list {
  margin-top: 30px; }
  .check_list .list_box {
    border: 2px solid #e3f0f4;
    width: 90%;
    margin: 0 auto;
    padding: 15px;
    font-size: 1.5rem;
    box-sizing: border-box;
    border-radius: 8px; }
    .check_list .list_box .item {
      position: relative; }
      .check_list .list_box .item:nth-of-type(2) {
        line-height: 2.4; }
      .check_list .list_box .item::before {
        content: "";
        display: inline-block;
        background: url(../images/icon_check.png) no-repeat;
        background-size: contain;
        width: 1.2em;
        height: 1.2em;
        position: relative;
        top: .2em;
        padding-right: .8em; }
  .check_list .kimura_msg {
    width: 80%;
    color: #f15e5e;
    font-size: 2rem;
    margin: 25px auto 0;
    padding-bottom: 20px;
    position: relative; }
    .check_list .kimura_msg::after {
      content: "";
      display: inline-block;
      width: 100px;
      height: 115px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../images/kimura_guts.png) no-repeat;
      background-size: contain; }

.difference {
  color: #fff;
  padding: 40px 8% 35px; }
  .difference .ttl {
    font-size: 2.3rem;
    text-align: center; }
  .difference .desc {
    font-size: 1.5rem;
    margin-top: 30px;
    line-height: 1.8; }
  .difference .underline {
    border-bottom: 3px solid #fdd000;
    display: inline-block;
    padding-bottom: 2px;
    color: #fff;
    text-shadow: 0px 1px 16px #33c4c9; }

.usage_area .ttl {
  font-size: 2.3rem;
  margin: 40px 0 35px;
  text-align: center;
  font-family: rounded_bold; }

.usage_area .measure_box {
  background: #fdd000;
  color: #444;
  font-size: 2rem;
  text-align: center;
  padding: 12px 0;
  font-family: rounded_bold; }
  .usage_area .measure_box .txt_line {
    color: #00b900; }
  .usage_area .measure_box .txt_tel {
    color: #00b5bc; }

.usage_area .conversation_area {
  background: url(../images/bg_polka-dot-thin.jpg);
  position: relative;
  padding-top: 45px; }
  .usage_area .conversation_area .balloon {
    width: 90%;
    height: 100px;
    position: relative;
    text-align: left; }
  .usage_area .conversation_area .balloon_txt {
    width: 90%;
    font-size: 1.3rem;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%; }
  .usage_area .conversation_area .balloon.left {
    background: url(../images/balloon02_left.png) no-repeat;
    background-size: 100%;
    position: relative;
    margin-left: 10%;
    width: 80%;
    height: 90px; }
    .usage_area .conversation_area .balloon.left::after {
      content: "";
      display: block;
      width: 50px;
      height: 50px;
      background: url(../images/icon_kimura.png) no-repeat;
      background-size: contain;
      position: absolute;
      bottom: -45px;
      left: -5%; }
    .usage_area .conversation_area .balloon.left .balloon_txt {
      transform: translate(-50%, -70%); }
  .usage_area .conversation_area .balloon.right {
    background: url(../images/balloon02_right.png) no-repeat;
    background-size: 100%;
    margin: 20px 10% 0 auto;
    margin-left: auto;
    width: 68%;
    height: 110px; }
    .usage_area .conversation_area .balloon.right::after {
      content: "";
      display: block;
      width: 50px;
      height: 50px;
      background: url(../images/icon_user.png) no-repeat;
      background-size: contain;
      position: absolute;
      bottom: -50px;
      right: -5%; }
    .usage_area .conversation_area .balloon.right .balloon_txt {
      transform: translate(-50%, -60%); }
  .usage_area .conversation_area .msg {
    background: url(../images/bg_cloud.png) no-repeat;
    background-size: contain;
    width: 170px;
    height: 107px;
    position: relative; }
  .usage_area .conversation_area .cloud_txt {
    position: absolute;
    top: 50%;
    transform: translateY(-30%);
    font-size: 2rem;
    color: #fff;
    padding-left: 10px; }
    .usage_area .conversation_area .cloud_txt .catch_color {
      color: #fdd000; }

.usage_area .proposal {
  font-size: 1.4rem;
  line-height: 1.8;
  width: 70%;
  margin: 40px auto;
  text-align: center; }

.media_coverage {
  width: 90%;
  margin: 30px auto 40px; }
  .media_coverage .ttl {
    font-size: 16px;
    font-family: rounded_bold;
    display: flex;
    align-items: center;
    text-align: center; }
    .media_coverage .ttl::before, .media_coverage .ttl::after {
      border-top: 3px solid #000;
      content: "";
      display: inline;
      flex-grow: 1; }
    .media_coverage .ttl::before {
      margin-right: .6em; }
    .media_coverage .ttl::after {
      margin-left: .6em; }
  .media_coverage .company_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 10px; }
    .media_coverage .company_list .item {
      width: auto;
      vertical-align: middle;
      margin-top: 30px; }
    .media_coverage .company_list .small {
      width: 41%; }
    .media_coverage .company_list .medium {
      width: 43%; }
    .media_coverage .company_list .large {
      width: 52%; }

.minique_concept {
  color: #fff;
  position: relative;
  width: 90%;
  margin: 30px auto 20px; }
  .minique_concept:before, .minique_concept:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    background: url(../images/square_brackets.png) no-repeat;
    background-size: cover; }
  .minique_concept:before {
    top: 0;
    left: 0; }
  .minique_concept:after {
    top: 90px;
    left: 0;
    transform: rotate(180deg); }
  .minique_concept .ttl {
    font-size: 2.7rem;
    padding-top: 20px;
    font-family: rounded; }
    .minique_concept .ttl .catch_color {
      color: #fdd000; }
  .minique_concept .catch_copy {
    font-size: 1.4rem;
    margin-top: 5px; }
  .minique_concept .img_logo {
    margin-left: 10px; }

.company_prof {
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
  background: #f4f2ec; }
  .company_prof .ttl {
    font-size: 18px;
    font-weight: bold;
    padding: 40px 0;
    text-align: center; }
  .company_prof .table {
    padding: 30px 5% 35px;
    font-size: 1.4rem;
    background: #fff; }
    .company_prof .table .defttl {
      font-weight: bold;
      padding-top: 25px; }
      .company_prof .table .defttl:first-of-type {
        border-top: 1px solid #ddd; }
    .company_prof .table .deflsit {
      margin-top: 5px;
      padding-bottom: 25px;
      border-bottom: 1px solid #ddd; }

.footer {
  background: #f4f2ec;
  padding: 15px 5% 20px; }
  .footer .copyright {
    opacity: .25;
    font-size: 10px; }
