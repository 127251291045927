@charset "UTF-8";
/*--------------------------------------------------
  Option
--------------------------------------------------*/
.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-00 {
  margin-bottom: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-00 {
  margin-top: 00px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-00 {
  margin-right: 00px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-00 {
  margin-left: 00px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-00 {
  padding-bottom: 0 !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-00 {
  padding-top: 00px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pr-00 {
  padding-right: 00px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pl-00 {
  padding-left: 00px !important;
}

.align-l {
  text-align: left !important;
}

.align-c {
  text-align: center !important;
}

.align-r {
  text-align: right !important;
}

.clrfix:after {
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  content: "."; }

.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
}

.pc_none {
  display: none;
}

@media screen and (max-width: 768px) {
  .sp_none {
    display: none;
  }
  .pc_none {
    display: block;
  }
}