@charset "UTF-8";

.img_logo {
  width: 72px;
  vertical-align: middle;
  margin-left: 5px;
}

.font_rounded {
  font-family: rounded_bold;
  color: #444;
}

.kv {
  background: url(../images/top_kv.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 580px;
  position: relative;
  text-align: center;
  color: #fff;
  font-family: rounded_bold;
    @media screen and (min-width: 640px) {
      background-size: contain;
    }
  .top_logo {
    font-size: 1.2rem;
    padding-top: 20px;
  }
  .ttl_top {
    font-size: 3.5rem;
    margin-top: 30px;
    font-family: rounded_bold;
    letter-spacing: -1px;
    @media screen and (max-width: 340px) {
      font-size: 9.3vw;
    }
  }
  .ttl_under {
    font-size: 1.8rem;
    margin-top: 15px;
    letter-spacing: 0.05em;
    letter-spacing: -.5px;
    @media screen and (max-width: 340px) {
      font-size: 4.8vw;
    }
  }
  .emphasis {
    text-emphasis: filled circle #fdd000;
    -webkit-text-emphasis: filled circle #fdd000;
  }
  .catch_color {
    color: #fdd000;
  }
}

.contact_wrap {
  padding: 40px 0;
  text-align: center;
  .btn_line {
    width: 90%;
    margin: 0 auto;
  }
  .contact_tel {
    background: #fff;
    padding: 20px 5%;
    margin-top: 30px;
    font-size: 1.8rem;
    border-radius: 8px;
  }
  .btn_tel {
    margin-top: 12px;
  }
  .reception_time {
    font-size: 1.2rem;
    margin-top: 15px;
  }
}

.about_concierge {
  padding: 40px 5% 30px;
  background: url(../images/bg_about_concierge.jpg) no-repeat;
  background-size: cover;
  text-align: center;
  .ttl {
    font-size: 1.8rem;
    color: #fff;
    text-shadow:0px 1px 5px rgba(0,0,0,.15);
  }
  .balloon_area {
    margin-top: 30px;
  }
  .balloon {
    width: 90%;
    height: 100px;
    position: relative;
    text-align: left;
  }
  .balloon_txt {
    width: 90%;
    font-size: 1.5rem;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -64%);
    @media screen and (max-width: 340px) {
      font-size: 4vw;
    }
  }
  .balloon.left {
    background: url(../images/balloon_left.png) no-repeat;
    background-size: 100%;
  }
  .balloon.right {
    background: url(../images/balloon_right.png) no-repeat;
    background-size: 100%;
    margin-left: auto;
    width: 86%;
  }
}

.kimura_area {
  margin-top: 40px;
  text-align: center;
  .ttl {
    color: #f15e5e;
    font-size: 3rem;
    display: inline-block;
  }
  .slash_txt {
    position: relative;
    &::before, &::after{
      content: "";
      width: 2px;
      height: 1em;
      position: absolute;
      display: inline-block;
      top: .2em;
      background-repeat: no-repeat;
      border-left: 2px solid #fcc;
    }
    &::before {
      transform: rotate(-20deg);
      left: -.8em
    }
    &::after {
      transform: rotate(20deg);
      right: -.8em
    }
  }
  .desc {
    font-size: 1.8rem;
    margin-top: 10px;
    color: #000;
  }
  .img_consal {
    margin-top: 20px;
  }
}

.reason_area {
  text-align: center;
  padding: 40px 0;
  .ttl {
    font-size: 2.5rem;
    color: #fff;
  }
  .reason_box {
    background: #fff;
    width: 84%;
    margin: 20px auto 0;
    padding: 20px;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 1px 3px 15px rgba(0,0,0,.15);
  }
  .detail_txt {
    font-size: 1.8rem;
    color: #000;
    .emphasis {
      color: #f15e5e;
    }
  }
  .img_reason {
    margin-top: 20px;
  }
  .img_small {
    width: 40%;
  }
  .img_large {
    width: 60%;
  }
}

.desc_concierge {
  width: 80%;
  margin: 30px auto 40px;
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.6;
  .txt {
    margin-top: 30px;
  }
}

.house_style {
  text-align: center;
  .style_wrap {
    background: #f4f2ec;
    padding: 20px 5%;
    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }
  .style_name {
    position: relative;
    font-size: 18px;
    font-family: rounded_bold;
  }
  .icon {
    font-size: 1em;
    position: relative;
    left: 1em;
    display: inline-block;
    &::before {
      content: "";
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: absolute;
      top: 0;
      left: -2em;
    }
  }
  .icon.icon_bungalow {
    &::before {
      background: url(../images/icon_house.png) no-repeat;
      background-size: contain;
    }
  }
  .icon.icon_sofa {
    &::before {
      background: url(../images/icon_sofa.png) no-repeat;
      background-size: contain;
    }
  }
  .icon.icon_brick {
    &::before {
      background: url(../images/icon_brick.png) no-repeat;
      background-size: contain;
    }
  }
  .icon.icon_modern {
    &::before {
      background: url(../images/icon_folding-fan.png) no-repeat;
      background-size: contain;
      top: .3em;
    }
  }
  .icon.icon_cafe {
    &::before {
      background: url(../images/icon_cup.png) no-repeat;
      background-size: contain;
      top: .4em;
    }
  }
  .icon.icon_simple {
    &::before {
      background: url(../images/icon_light.png) no-repeat;
      background-size: contain;
    }
  }
  .img_style {
    width: 100%;
    margin-top: 15px;
  }
  .coverage {
    font-size: 12px;
    text-align: right;
    margin-top: 10px;
    color: #666;
  }
}

.check_list {
  margin-top: 30px;
  .list_box {
    border: 2px solid #e3f0f4;
    width: 90%;
    margin: 0 auto;
    padding: 15px;
    font-size: 1.5rem;
    box-sizing: border-box;
    border-radius: 8px;
    .item {
      position: relative;
      &:nth-of-type(2) {
        line-height: 2.4;
      }
      &::before {
        content: "";
        display: inline-block;
        background: url(../images/icon_check.png) no-repeat;
        background-size: contain;
        width: 1.2em;
        height: 1.2em;
        position: relative;
        top: .2em;
        padding-right: .8em;
      }
    }
  }
  .kimura_msg {
    width: 80%;
    color: #f15e5e;
    font-size: 2rem;
    margin: 25px auto 0;
    padding-bottom: 20px;
    position: relative;
    &::after {
      content: "";
      display: inline-block;
      width: 100px;
      height: 115px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../images/kimura_guts.png) no-repeat;
      background-size: contain;
    }
  }
}

.difference {
  color: #fff;
  padding: 40px 8% 35px;
  .ttl {
    font-size: 2.3rem;
    text-align: center;
  }
  .desc {
    font-size: 1.5rem;
    margin-top: 30px;
    line-height: 1.8;
  }
  .underline {
    border-bottom: 3px solid #fdd000;
    display: inline-block;
    padding-bottom: 2px;
    color: #fff;
    text-shadow: 0px 1px 16px #33c4c9;
  }
}

.usage_area {
  .ttl {
    font-size: 2.3rem;
    margin: 40px 0 35px;
    text-align: center;
    font-family: rounded_bold;
  }
  .measure_box {
    background: #fdd000;
    color: #444;
    font-size: 2rem;
    text-align: center;
    padding: 12px 0;
    font-family: rounded_bold;
    .txt_line {
      color: #00b900;
    }
    .txt_tel {
      color: #00b5bc;
    }
  }
  .conversation_area {
    background: url(../images/bg_polka-dot-thin.jpg);
    position: relative;
    padding-top: 45px;
    .balloon {
      width: 90%;
      height: 100px;
      position: relative;
      text-align: left;
    }
    .balloon_txt {
      width: 90%;
      font-size: 1.3rem;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
    }
    .balloon.left {
      background: url(../images/balloon02_left.png) no-repeat;
      background-size: 100%;
      position: relative;
      margin-left: 10%;
      width: 80%;
      height: 90px;
      &::after {
        content: "";
        display: block;
        width: 50px;
        height: 50px;
        background: url(../images/icon_kimura.png) no-repeat;
        background-size: contain;
        position: absolute;
        bottom: -45px;
        left: -5%;
      }
      .balloon_txt {
        transform: translate(-50%,-70%);
      }
    }
    .balloon.right {
      background: url(../images/balloon02_right.png) no-repeat;
      background-size: 100%;
      margin: 20px 10% 0 auto;
      margin-left: auto;
      width: 68%;
      height: 110px;
      &::after {
        content: "";
        display: block;
        width: 50px;
        height: 50px;
        background: url(../images/icon_user.png) no-repeat;
        background-size: contain;
        position: absolute;
        bottom: -50px;
        right: -5%;
      }
      .balloon_txt {
        transform: translate(-50%,-60%);
      }
    }
    .msg {
      background: url(../images/bg_cloud.png) no-repeat;
      background-size: contain;
      width: 170px;
      height: 107px;
      position: relative;
    }
    .cloud_txt {
      position: absolute;
      top: 50%;
      transform: translateY(-30%);
      font-size: 2rem;
      color: #fff;
      padding-left: 10px;
      .catch_color {
        color: #fdd000;
      }
    }
  }
  .proposal {
    font-size: 1.4rem;
    line-height: 1.8;
    width: 70%;
    margin: 40px auto;
    text-align: center;
  }
}

.media_coverage {
  width: 90%;
  margin: 30px auto 40px;
  .ttl {
    font-size: 16px;
    font-family: rounded_bold;
    display: flex;
    align-items: center;
    text-align: center;
    &::before, &::after {
      border-top: 3px solid #000;
      content: "";
      display: inline;
      flex-grow: 1;
    }
    &::before {
      margin-right: .6em;
    }
    &::after{
      margin-left: .6em;
    }
  }
  .company_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 10px;
    .item {
      width: auto;
      vertical-align: middle;
      margin-top: 30px;
    }
    .small {
      width: 41%;
    }
    .medium {
      width: 43%;
    }
    .large {
      width: 52%;
    }
  }
}

.minique_concept {
  color: #fff;
  position: relative;
  width: 90%;
  margin: 30px auto 20px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    background:url(../images/square_brackets.png) no-repeat;
    background-size: cover;
  }
  &:before {
    top: 0;
    left: 0;
  }
  &:after {
    top: 90px;
    left: 0;
    transform: rotate(180deg);
  }
  .ttl {
    font-size: 2.7rem;
    padding-top: 20px;
    font-family: rounded;
    .catch_color {
      color: #fdd000;
    }
  }
  .catch_copy {
    font-size: 1.4rem;
    margin-top: 5px;
  }
  .img_logo {
    margin-left: 10px;
  }
}

.company_prof {
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
  background: #f4f2ec;
  .ttl {
    font-size: 18px;
    font-weight: bold;
    padding: 40px 0;
    text-align: center;
  }
  .table {
    padding: 30px 5% 35px;
    font-size: 1.4rem;
    background: #fff;
    .defttl {
      font-weight: bold;
      padding-top: 25px;
      &:first-of-type {
        border-top: 1px solid #ddd;
      }
    }
    .deflsit {
      margin-top: 5px;
      padding-bottom: 25px;
      border-bottom: 1px solid #ddd;
    }
  }
}

.footer {
  background: #f4f2ec;
  padding: 15px 5% 20px;
  .copyright {
    opacity: .25;
    font-size: 10px;
  }
}




@media screen and (min-width: 768px) {
}