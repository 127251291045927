@charset "UTF-8";
/*=========================================================
  mainのコンテンツスタイルはこちらに記述していきます。
=========================================================*/
img {
  width: 100%; }

.inner {
  width: 90%;
  margin: 0 auto; }

.bg_polkadot {
  background: url(../images/bg_polka-dot.jpg);
  background-size: cover; }

.bg_beige {
  background: #f4f2ec; }
