@charset "UTF-8";
/*=========================================================
  基本のフォントの指定を記述。
=========================================================*/
@font-face {
  font-family: "rounded";
  src: url("../../assets/font/rounded-mplus-1c-medium.ttf"); }

@font-face {
  font-family: "rounded_bold";
  src: url("../../assets/font/rounded-mplus-1c-bold.ttf"); }

body {
  font-family: "源ノ角ゴシック JP Normal", "Source Han Sans JP Normal", Meiryo, sans-serif;
  color: #000; }
